import * as React from "react";
import styled from "styled-components";
import { Img } from "wmk-image";
import { colors } from "../../../../vars/palette";

const MapMarker = ({ wmkLogo }: { wmkLogo: Img }) => {
  return (
    <LogoWrap>
      <Logo src={wmkLogo.url()} alt="Watermark Logo" />
    </LogoWrap>
  );
};

export default MapMarker;

const LogoWrap = styled.div`
  width: 110px;
  height: 110px;
  background: lightGray;
  border-radius: 10% 100% 100% 100%;
  border: 3px solid ${colors.hex("white")};
  padding: 40px 0 0 12px;
`;

const Logo = styled.img`
  width: 80px;
  height: 30px;
`;
