import * as React from "react"; //, { useState } /'
import styled from "styled-components";
import { Img, WmkImage } from "wmk-image";
import { PeopleFields } from "../../../fragments/nodePeopleFields";
import ImageLoop from "./ImageLoop";
//import ImageLoop from "./ImageLoop";
import { NameLink } from "./TeamSingleNameLink";

const TeamSingle = ({ memberInfo }: { memberInfo: PeopleFields }) => {
  const { rolloverGallery, websiteUrl, name, jobTitle, mainImage } = memberInfo;
  return (
    <MemberWrap>
      <ImageLoop
        mainImage={new Img(mainImage)}
        memberInfo={{ websiteUrl, name, jobTitle }}
        pics={
          Array.isArray(rolloverGallery)
            ? rolloverGallery.map((r) => new Img(r))
            : []
        }
      />
      <InfoWrap>
        <NameLink websiteUrl={websiteUrl}>{name}</NameLink>
        <p>{jobTitle}</p>
      </InfoWrap>
    </MemberWrap>
  );
};

export default TeamSingle;

const MemberWrap = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
`;

const InfoWrap = styled.div`
  // text-align: left;
  position: relative;
  left: 0;
  a:hover {
  }

  p {
    font-size: 75%;
    margin-top: -10px;
  }
`;
