import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import SubHeading from "../../common/typography/SubHeading";
import Map from "./Map/Map";
import { WmkLink } from "wmk-link";
import { colors } from "../../../vars/palette";
import { Img } from "wmk-image";

const SecVisitUs = ({ wmkLogo }: { wmkLogo: Img }) => {
  const mapContactInfo = useStaticQuery(graphql`
    {
      contentfulGlobalOptions(slug: { eq: "main-options" }) {
        ...NodeGlobalOptions
      }
    }
  `).contentfulGlobalOptions;

  const shortState = mapContactInfo.addressState.slice(0, 2).toUpperCase();
  const formattedPhone = mapContactInfo.phoneOffice
    .slice(2)
    .replace(/[)(]/g, "")
    .replace(/[-]/g, ".");

  return (
    <Wrap>
      <Container>
        <br></br>
        <Row>
          <LineLeftCol>
            <SubHeading text="VISIT US" />
            <AddressWrap>
              <p>{mapContactInfo.addressStreet}</p>
              <p>
                {mapContactInfo.addressCity +
                  `, ` +
                  shortState +
                  ` ` +
                  mapContactInfo.addressZipCode}
              </p>
              <WmkLink tel>{formattedPhone}</WmkLink>
            </AddressWrap>
          </LineLeftCol>
        </Row>
      </Container>
      <MapWrap>
        <Map logo={wmkLogo} />
      </MapWrap>
    </Wrap>
  );
};

export default SecVisitUs;

const Wrap = styled.div`
  margin: 2rem 0;
`;

const LineLeftCol = styled(Col)`
  border-left: 4px solid ${colors.hex("secondary")};
  padding-bottom: 8rem;
  height: 17rem;
  position: relative;
  z-index: 10;
`;

const MapWrap = styled.div`
  margin-top: -4rem;
  height: 40rem;
  width: 100vw;
`;

const AddressWrap = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.5rem;
  a {
    color: ${colors.hex("secondary")};
  }
`;
