import React from "react";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";
import MapMarker from "./Marker";
import { Img } from "wmk-image";

const Map = ({
  center = {
    lat: 39.750017,
    lng: -104.886813,
  },
  zoom = 16,
  logo,
}: {
  center: { lat: number; lng: number };
  zoom: number;
  logo: Img;
}) => {
  return (
    <MapWrap>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCY57PGeCv9FbsAI7vZLkMSN1ea8BryOXs" }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        <MapMarker lat={center.lat} lng={center.lng} wmkLogo={logo} />
      </GoogleMapReact>
    </MapWrap>
  );
};

// class Map extends Component {
//   static defaultProps: = {
//     center: {
//       lat: 39.750017,
//       lng: -104.886813
//     },
//     zoom: 16
//   };

//   render() {
//     return (
//       // Important! Always set the container height explicitly

//     );
//   }
// }

export default Map;

const MapWrap = styled.div`
  height: 40rem;
  width: 100vw;
`;
