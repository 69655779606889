import * as React from "react";
import { Row, Col } from "react-bootstrap";
import GelbertTile from "./GelbertTile";
import styled from "styled-components";
import { CaseStudy, CaseStudy3 } from "../../../../../classes/CaseStudy";

const StyledRow = styled(Row)`
  background: white;
  position: relative;
  z-index: 100;
  margin-bottom: -4rem;
  @media only screen and (min-width: 767px) {
    min-height: 400px;
  }
`;

export const Tiles = ({ works }: { works: (CaseStudy | CaseStudy3)[] }) => {
  return (
    <StyledRow>
      {works && works.length
        ? works.map((work, i) => {
            return (
              <Col
                key={work.clientName + i}
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={3}
                style={{ padding: 0 }}
              >
                <GelbertTile work={work} />
              </Col>
            );
          })
        : null}
    </StyledRow>
  );
};
