import React, { useState } from "react";
import { Row, Container, Col } from "react-bootstrap";
import styled from "styled-components";
import Theme from "../../../../../vars/ThemeOptions";
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";

const StyledFilter = styled(Row)`
  ${Theme.gradient.wmk}
  position: relative;
  z-index: 1001;
  border-top: 2px solid ${Theme.hex("white")};
  border-bottom: 2px solid ${Theme.hex("white")};
  color: ${Theme.hex("white")};
  padding: 1vh 0;
  *:focus,
  *:active {
    outline: none;
  }
`;

const FilterMenuButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  color: ${Theme.hex("white")};
  text-transform: uppercase;
  .carets {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-top: -1px;
    svg:first-of-type {
      margin-bottom: -4px;
    }
  }
`;

const FilterMenuDrawer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1000;
  button {
    width: 400px;
    border: none;
    ${Theme.gradient.rust}
    color: ${Theme.hex("white")};
  }
  &.closed {
    top: -20rem;
    left: -100vw;
    z-index: -10000000;
    opacity: 0;
    transition: z-index 0.05s ease 0.3s, left 0.05s ease 0.3s, top 0.3s ease,
      opacity 0.3s ease;
  }
  &.open {
    top: 2.5rem;
    left: 0vw;
    z-index: 1000;
    opacity: 1;
    transition: z-index 0.05s ease, left 0.05s ease, top 0.3s ease 0.05s,
      opacity 0.3s ease 0.05s;
  }
  @media only screen and (max-width: 756px) {
    button {
      width: 100%;
    }
  }
`;

export const Filter = ({
  cats,
  currCat,
  updateCurrCat,
}: {
  cats: string[];
  currCat: string;
  updateCurrCat: (clickedCat: any) => void;
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const clickCat = (clickedCat: string) => {
    updateCurrCat(clickedCat);
    setIsDrawerOpen(false);
  };
  return (
    <StyledFilter>
      <Container>
        <Row>
          <Col style={{ position: "relative" }}>
            <FilterMenuButton onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
              {currCat}
              <div className="carets">
                <AiFillCaretUp size="1rem" />
                <AiFillCaretDown size="1rem" />
              </div>
            </FilterMenuButton>
            <FilterMenuDrawer className={isDrawerOpen ? "open" : "closed"}>
              {cats.map((cat) => {
                return (
                  <button onClick={() => clickCat(cat)} key={`cat-` + cat}>
                    {cat}
                  </button>
                );
              })}
            </FilterMenuDrawer>
          </Col>
        </Row>
      </Container>
    </StyledFilter>
  );
};
