import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Gallery } from "../GelbertGallery";
import { CaseStudies } from "../../../../classes/CaseStudy";
import {
  CaseStudyFields,
  CaseStudyV3Fields,
} from "../../../../fragments/nodeCaseStudyFields";

const query = graphql`
  {
    cases: allContentfulWorkCaseStudyOld(filter: {title: {ne: "schema"}}) {
      edges {
        node {
          ...NodeCaseStudyExcerptFields
        }
      }
    }
    casesNew: allContentfulCaseStudy(filter: {title: {ne: "schema"}}) {
      edges {
        node {
          ...NodeCaseStudyV3Fields
        }
      }
    }
  }
`;

type AllWorksQuery = {
  cases: { edges: { node: CaseStudyFields }[] };
  short: { edges: { node: CaseStudyFields }[] };
  casesNew: { edges: { node: CaseStudyV3Fields }[] };
};

const AllWorks = () => {
  const allWorks: AllWorksQuery = useStaticQuery(query);
  const caseStudies = new CaseStudies([
    ...allWorks.cases.edges,
    ...allWorks.casesNew.edges,
  ]);
  return <Gallery works={caseStudies} />;
};

export default AllWorks;
