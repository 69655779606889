import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import PageTitleBar from "../../common/PageTitleBar";
import SecVisitUs from "./SecVisitUs";
//import ContactForm from "./ContactForm";
import { ContentfulImageQuery, Img } from "wmk-image";
import ContactFormHsEmbed from "./ContactFormHsEmbed";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { colors } from "../../../vars/palette";
import SubHeading from "../../common/typography/SubHeading";

const LineLeftCol = styled(Col)`
  border-left: 4px solid ${colors.hex("secondary")};
  padding-bottom: 4rem;
  margin-top: 3rem;
  position: relative;
  z-index: 10;
  margin-bottom: -4rem;
`;

const CalendarWrap = styled.div`
  .meetings-iframe-container {
    height: 690px;
  }
`;

const Contact = () => {
  const data: { hero: ContentfulImageQuery; icon: ContentfulImageQuery } =
    useStaticQuery(graphql`
      {
        hero: contentfulAsset(title: { eq: "Contact Hero Image" }) {
          ...NodeImageFields
          gatsbyImageData
        }
        icon: contentfulAsset(title: { eq: "LogoMark png" }) {
          ...NodeImageFields
          gatsbyImageData
        }
      }
    `);

  const [display, setDisplay] = React.useState();
  const [helmetDisplay, setHelmetDisplay] = React.useState();

  React.useEffect(() => {
    setDisplay(true);
  }, []);

  React.useEffect(() => {
    setHelmetDisplay(true);
  }, []);

  const displayCalendar = () => {
    return (
      <div
        class="meetings-iframe-container"
        style={{ paddingTop: "1rem" }}
        data-src="https://meetings.hubspot.com/darby-kim/contact-us-meeting-scheduled?embed=true"
      ></div>
    );
  };

  return (
    <>
      <Helmet>
        {/* <!-- Start of Meetings Embed Script --> */}   {" "}
        {helmetDisplay && (
          <script
            type="text/javascript"
            src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
          />
        )}
        {/*   <!-- End of Meetings Embed Script --> */}
      </Helmet>
      <PageTitleBar title="Contact" />
      <Container>
        <Row>
          <LineLeftCol>
            <SubHeading text="We Can't Reach You Until You Reach Us!" />
              <p>Schedule a time to meet with a digital expert</p>
          </LineLeftCol>
        </Row>
      </Container>
      <CalendarWrap>{display && displayCalendar()}</CalendarWrap>

      {/* <ContactFormHsEmbed 
        title="We Can't Reach You Until You Reach Us!" 
        image={new Img(data.hero)}  
      /> */}
      {/* <ContactForm
        title="We Can't Reach You Until You Reach Us!"
        subtitle="Choose an option below. We'll get right back to you."
        image={new Img(data.hero)}
        forms={{
          buttons: [
            {
              icon: "help",
              label: "Need Help?",
              formId: "72f65945-891c-4db5-9b69-6acb84b812d0",
            },
            {
              icon: "careers",
              label: "Need a Career?",
              formId: "f8975b1d-6531-4584-bead-10da567b28cb",
            },
          ],
          portalId: "526959",
        }}
      /> */}
      <SecVisitUs wmkLogo={new Img(data.icon)} />
    </>
  );
};

export default Contact;
