import * as React from "react";
import Layout from "../components/layout/MainLayout";
import { graphql } from "gatsby";
import PageBlogBefore from "../components/routes/Blog/AllBlogs";
import PageTeamBefore from "../components/routes/Team/Team";
import PageContactBefore from "../components/routes/Contact/Contact";
import SolutionsForm from "../components/routes/Solutions/Form";
import { WmkSeo } from "wmk-seo";
import { baseUrl, siteTitle, twitter } from "../vars/ThemeOptions";
import { Page } from "../classes/Page";
import AllWorks from "../components/routes/Work/AllWork/AllWork";
import { PageFields } from "../fragments/nodePageFields";
import { RichText, RichTextReact } from "wmk-rich-text";
import { options } from "../components/blocks/RichText/richTextOptions";
import { BlogBackContext } from "../context/BlogBackContext";
import styled from "styled-components";
import { colors } from "../vars/palette";
import { Col, Container, Row } from "react-bootstrap";
import { BlockTwoColumn } from "../fragments/blockTwoColumnFields";
import BlockIconCopy from "../components/blocks/library/BlockIconCopy";
import BlockTitleTextAndIconsRow from "../components/blocks/library/BlockTitleTextAndIconsRow";
import { BlockTitleTextAndIconsRowFields } from "../fragments/blockTitleTextAndIconsRowFields";

const AboutPageWrap = styled.div`
  .icon-copy-text > p {
    color: ${colors.hex("text")} !important;
  }
`;

const AboutAfterWrap = styled.div`
  .icon-copy-row {
    padding-top: 3rem;
  }
  .icon-copy-row:nth-child(even) {
    position: relative;
    top: 40%;
  }
  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
  }

  .icon-copy-row > div:nth-child(even) {
    // top: 7rem;
    position: relative;
  }
  .icon-copy-text {
    padding-top: 1rem;
  }
  .icon-copy-display > div:first-child {
    align-items: center;
    // margin-left: 1rem;
    margin: 0;
  }
  .icon-copy-display {
    padding-bottom: 2rem;
    p {
      color: #696969;
    }

    strong {
      font-weight: 600;
    }

    li::marker {
      content: " ";
      color: transparent;
    }

    li:first-child {
      &::marker {
        content: "// ";
        color: #e9571c;
      }
    }
  }
  .left-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding-top: 3rem;
    padding-bottom: 3rem;
    order: 1;
    padding-top: 3rem;
  }
  .right-col {
    order: 2;
  }
  @media only screen and (max-width: 991px) {
    .left-col {
      text-align: center;
    }
  }
  @media only screen and (max-width: 767px) {
    .icon-copy-row {
      padding-top: 1rem;
    }
    .left-col {
      order: 1;
      padding-top: 3rem;
    }
    .right-col {
      order: 2;
    }
  }
  @media only screen and (max-width: 575px) {
    
    .icon-copy-row > div:nth-child(even) {
      top: 0rem;
    }
    .right-col {
      text-align: center;
      ul {
        padding-left: 0;
      }
    }
    .icon-copy-display > div:first-child {
      align-items: center;
      margin-left: 0;
    }
    .icon-copy-display {
      li:first-child {
        &::marker {
          content: " ";
          color: transparent;
        }
      }
    }
  }
`;

const PageNode = ({ data }: PageQuery) => {
  const meta = data.meta;
  const page = new Page(data.page);
  const slug = page.slug;
  const _tb = page.titleBar;
  const title = _tb ? _tb : page.title;
  let RenderBefore = null;
  let RenderAfter = null;
  switch (slug) {
    case "work":
      RenderBefore = <AllWorks />;
      break;
    case "blog":
      RenderBefore = (
        <BlogBackContext.Consumer>
          {(data) => {
            return (
              <>
                <PageBlogBefore loadedPages={data.page} />;
              </>
            );
          }}
        </BlogBackContext.Consumer>
      );
      break;
    case "team":
      RenderBefore = <PageTeamBefore />;
      break;
    case "contact":
      RenderBefore = <PageContactBefore />;
      break;
    // case "solutions":
    //   RenderBefore = (
    //     <>
    //       <SolutionsPageHero />
    //       <ExploreOurFlywheelSection />
    //     </>
    //   );
    //   RenderAfter = (
    //     <>
    //       <SolutionsForm />
    //     </>
    //   );
    //   break;
    case "solutions":
      RenderAfter = <SolutionsForm />;
      break;
    case "about":
      const leftCol = new RichText(data.aboutTwoCol.leftColumn);
      const aboutCTADisplay = new RichText(data.aboutCTA);
      RenderAfter = (
        <AboutAfterWrap>
          <Container>
            <Row>
              <Col
                // xs={{ span: 12, order: leftStackOrder }}
                // lg={{ span: leftWidth, order: 1 }}
                className="left-col"
                lg={12}
              >
                <RichTextReact content={leftCol} options={options} />
              </Col>
              {/* {spacerWidth && <Col lg={spacerWidth} />} */}
              <Col className="right-col">
                <Row className="icon-copy-row">
                  {data.aboutTwoCol.rightColumn.references.map(
                    (iconCopyData) => {
                      return (
                        <Col className="icon-copy-display" sm={6} md={6} lg={4}>
                          <BlockIconCopy block={iconCopyData} />
                        </Col>
                      );
                    }
                  )}
                </Row>
              </Col>
            </Row>
          </Container>
          <BlockTitleTextAndIconsRow block={data.aboutCTA} />
          <SolutionsForm />
        </AboutAfterWrap>
      );
      break;
    default: //<h2>No Page template for <em>{pageContext.slug}</em> route! {console.log(pageContext)}</h2>
      RenderBefore = null;
      RenderAfter = null;
  }
  return (
    <Layout>
      <WmkSeo.Meta
        title={title}
        path="/"
        slug={slug}
        description={page.meta}
        ogImageUrl={""}
        twitterImageUrl={""}
        twitterHandle={twitter}
        siteTitle={siteTitle}
        baseUrl={baseUrl}
      />
      <WmkSeo.Schema
        graphs={[
          {
            "@type": "WebPage",
            "@id": meta.baseUrl + "/#page-" + slug,
            url: meta.baseUrl,
            inLanguage: "en-US",
            name: title,
            isPartOf: {
              "@id": meta.baseUrl + "/#website",
            },
            about: {
              "@id": meta.baseUrl + "/#organization",
            },
            description: page.meta,
          },
        ]}
        siteTitle={siteTitle}
        baseUrl={baseUrl}
      />
      {RenderBefore}
      {slug === "about" ? (
        <AboutPageWrap>
          <RichTextReact content={page.content} options={options} />
        </AboutPageWrap>
      ) : (
        <RichTextReact content={page.content} options={options} />
      )}
      {RenderAfter}
    </Layout>
  );
};

export default PageNode;

export interface PageQuery {
  data: {
    page: PageFields;
    meta: {
      title: string;
      baseUrl: string;
    };
    aboutTwoCol: BlockTwoColumn;
    aboutCTA: BlockTitleTextAndIconsRowFields;
  };
}

export const query = graphql`
  query pageQuery($slug: String!) {
    page: contentfulWmkPages(slug: { eq: $slug }) {
      ...NodePageFields
    }
    meta: contentfulGlobalOptions(slug: { eq: "main-options" }) {
      title: siteTitle
      baseUrl: siteUrl
    }
    aboutTwoCol: contentfulBlockTwoColumn(title: { eq: "Our Core Values" }) {
      ...BlockTwoColumnFields
    }
    aboutCTA: contentfulBlockTitleTextAndIconsRow(
      contentfulid: {eq: "about > Explore Our Solutions"}
    ) {
      ...BlockTitleTextAndIconsRowFields
    }
  }
`;
