import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { colors } from "../../../vars/palette";
import { altButtonStyles } from "./ButtonStyles";
import HubspotForm from "react-hubspot-form";
import { ContentfulAssetQuery } from "wmk-image";
import ReactMarkdown from "react-markdown";

const Wrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 4rem 1rem;
  margin-bottom: 2rem;
`;
const ContentWrap = styled.div`
  max-width: 40rem;
  text-align: center;
  h2 {
    font-weight: bold;
    color: ${colors.hex("secondary")};
  }
  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    strong {
      font-weight: 800;
    }
  }
`;
const FormWrap = styled.div`
  input,
  textarea {
    // width: 20rem;
    width: 100%;
    max-width: 95vw;
    border-radius: 0;
    border: 2px solid #bcb7b7;
    background: none;
    padding-left: 0.5rem;
    &:hover {
      border: 2px solid ${colors.hex("secondary")};
    }
  }
  .hs-submit {
    margin: 2rem auto 1rem;
    max-width: 20rem;
    input {
      ${altButtonStyles}
    }
  }
  ul {
    margin-bottom: 0;
    list-style-type: none;
    color: ${colors.hex("primary")};
  }
  .hs-error-msg {
    position: relative;
    left: -1rem;
    display: block;
    margin-bottom: -1rem;
    color: ${colors.hex("secondary")};
  }
  .hs-main-font-element {
    // "Please complete all required fields"
    position: relative;
    left: -1rem;
    color: ${colors.hex("secondary")};
  }
  .hs-recaptcha {
    display: none;
  }
`;
const BgWrap = styled.div<{ bgSrc?: string }>`
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0.05;
  overflow: hidden;
  ${({ bgSrc }) =>
    bgSrc
      ? `background-image: url("${bgSrc}"); background-size: cover; background-repeat: no-repeat;`
      : ``}
`;

const LinkLocation = styled.div`
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: calc(-30% - 110px);
  @media only screen and (max-width: 767px) {
    top: calc(-30% - 200px);
  }
`;

const SolutionsForm = ({
  formHeader,
  formDescription,
}: {
  formHeader: string;
  formDescription: string;
}) => {
  const wmkLogo: ContentfulAssetQuery = useStaticQuery(graphql`
    query {
      asset: contentfulAsset(title: { eq: "Watermark Logomark" }) {
        file {
          url
        }
      }
    }
  `).asset;
  const wmkLogoUrl = wmkLogo.file.url;
  return (
    <Wrap>
      <LinkLocation id="solutions-form"></LinkLocation>
      <ContentWrap>
        {formHeader && formDescription ? (
          <>
            <h2>{formHeader}</h2>
            <ReactMarkdown>{formDescription}</ReactMarkdown>
          </>
        ) : (
          <>
            <h2>It's ok to test the waters.</h2>
            <p>
              We get it. Not every agency is a right fit for your project goals.
              That’s why we offer zero-commitment discovery workshops to unpack
              your project goals and create your custom solution.
            </p>
            <p>Contact us today to schedule your discovery workshop!</p>
          </>
        )}
        <FormWrap>
          <HubspotForm
            portalId={"526959"}
            formId={formDescription?.includes("Manufacuturing") ? "b4bc62a2-ba2d-4442-a546-8d43f23af562" :"dd0d7315-0376-4245-8125-fcb821738798"}
            onSubmit={() => {}}
            onReady={() => {}}
          />
        </FormWrap>
      </ContentWrap>
      <BgWrap bgSrc={wmkLogoUrl} />
    </Wrap>
  );
};

export default SolutionsForm;
