import * as React from "react";
import Swiper from "./BlockSwipeController";
import { graphql, useStaticQuery } from "gatsby";
import {
  CaseStudyFields,
  CaseStudyV3Fields,
} from "../../../../fragments/nodeCaseStudyFields";
import {
  CaseStudies,
  CaseStudy,
  CaseStudy3,
} from "../../../../classes/CaseStudy";

type SwipeQuery = {
  cases: { edges: { node: CaseStudyFields }[] };
  casesNew: { edges: { node: CaseStudyV3Fields }[] };
};

const Swipe = () => {
  const data: SwipeQuery = useStaticQuery(graphql`
    {
      cases: allContentfulWorkCaseStudyOld(
        limit: 3
        sort: { order: DESC, fields: launchDate }
      ) {
        edges {
          node {
            ...NodeCaseStudyFields
          }
        }
      }
      casesNew: allContentfulCaseStudy(
        limit: 3
        sort: { order: DESC, fields: launchDate }
      ) {
        edges {
          node {
            ...NodeCaseStudyV3Fields
          }
        }
      }
    }
  `);
  const cases = new CaseStudies([...data.cases.edges, ...data.casesNew.edges]);
  // filter out schema entries
  const noSchema = (entry: CaseStudy | CaseStudy3) => {
    return !entry.slug.includes("schema");
  };
  const casesListNoSchema = cases.list.filter(noSchema);
  cases.list = casesListNoSchema;
  return (
    <div>
      <Swiper latest={cases.list.slice(0, 3)} />
    </div>
  );
};

export default Swipe;
