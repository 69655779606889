import React, { useState } from "react";
import styled from "styled-components";
import { Fade } from "react-awesome-reveal";
import { colors, WMKGradient } from "../../../vars/palette";
import { mq } from "../../../vars/mediaQueries";
import { WmkImage, Img } from "wmk-image";

const ImageLoop = ({
  mainImage,
  pics,
  memberInfo,
}: {
  pics: Img[];
  mainImage: Img;
  memberInfo: { websiteUrl: string; name: string; jobTitle: string };
}) => {
  const [count, setCount] = useState(0);
  const [animated, setAnimated] = useState(false);
  const [interval, setTheInterval] = useState<NodeJS.Timer>();

  const incrementCount = () => {
    setCount((prev) => {
      const next = pics && prev + 1 < pics.length ? prev + 1 : 0;
      return next;
    });
  };
  const { websiteUrl, name } = memberInfo;
  return (
    <MemberPicWrap
      onMouseEnter={() => {
        setAnimated(true);
        if (pics && pics.length) {
          setTheInterval((prev) => {
            return setInterval(incrementCount, 350);
          });
        }
      }}
      onMouseLeave={() => {
        setAnimated(false);
        setCount(0);
        clearInterval(interval);
      }}
      href={websiteUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ImageLoopDiv>
        <LinkedInOverlayWrap>
          <LinkedInOverlay className="linked">LinkedIn</LinkedInOverlay>
        </LinkedInOverlayWrap>
        {pics && pics.length ? (
          <>
            <Fade triggerOnce>
              <MainImage image={mainImage} className="mainImage" />
            </Fade>
            {pics &&
              pics.length &&
              pics.map((pic, i) => {
                return (
                  <GrayedImage
                    image={pic}
                    key={"anim-" + name + i}
                    className={
                      count === i ||
                      count === i + 1 ||
                      (count === pics.length - 1 && i === 0)
                        ? "active"
                        : undefined
                    }
                    animated={animated}
                  />
                );
              })}
          </>
        ) : (
          <>
            <MainImage image={mainImage} className="mainImage" />
            <GrayedImage image={mainImage} className="grayedImage" />
          </>
        )}
      </ImageLoopDiv>
    </MemberPicWrap>
  );
};

export default ImageLoop;

// =======================
// STYLED COMPONENTS
// =======================

const imageDivsWidth = "18rem";
const imageDivsHeight = "30rem";

const imageDivsWidthAtOrBelow767 = "80vw";
const imageDivsHeightAtOrBelow767 = "130vw";

const imageDivsWidth768to940 = "13rem";
const imageDivsHeight768to940 = "22rem";

const imageDivsWidth992to1150 = "13rem";
const imageDivsHeight992to1150 = "22rem";

const imageDivsWidth1151to1350 = "15rem";
const imageDivsHeight1151to1350 = "25rem";

const mediaQueries = `${mq.max(
  "sm",
  `width: ${imageDivsWidthAtOrBelow767};
  height: ${imageDivsHeightAtOrBelow767};`
)}
${mq.range(
  "sm",
  "loopMd",
  `width: ${imageDivsWidth768to940};
  height: ${imageDivsHeight768to940};`
)}
${mq.range(
  "loopMd",
  "lg",
  `width: ${imageDivsWidth992to1150};
  height: ${imageDivsHeight992to1150};`
)}
${mq.range(
  "lq",
  "loopXl",
  `width: ${imageDivsWidth1151to1350};
  height: ${imageDivsHeight1151to1350};`
)}
`;

const MemberPicWrap = styled.a`
  width: ${imageDivsWidth};
  height: ${imageDivsHeight};
  &:hover {
    .linked {
      right: 0;
    }
    .mainImage {
      opacity: 0;
    }
    .grayedImage {
      opacity: 1;
    }
  }
  ${mediaQueries}
`;
const ImageLoopDiv = styled.div`
  position: relative;
  .react-reveal {
    position: relative !important;
  }
  width: ${imageDivsWidth};
  height: ${imageDivsHeight};
  overflow: hidden;
  ${mediaQueries}
`;

const MainImage = styled(WmkImage)`
  width: ${imageDivsWidth};
  height: ${imageDivsHeight};
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 1;
  ${mediaQueries}
`;

const GrayedImage = styled(WmkImage)<{
  animated?: boolean;
}>`
  &.active {
    opacity: ${({ animated }) => (animated ? 1 : 0)};
    z-index: ${({ animated }) => (animated ? 2 : 0)};
  }
  width: ${imageDivsWidth};
  height: ${imageDivsHeight};
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  position: absolute !important;
  z-index: -1;
  opacity: 0;
  top: 0;
  left: 0;
  ${mediaQueries};
`;

const LinkedInOverlayWrap = styled.div`
  position: relative;
  z-index: 200;
  height: 100%;
`;

const LinkedInOverlay = styled.div`
  position: absolute;
  z-index: 200;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  padding-top: 0.4rem;
  right: -10rem;
  top: 26rem;
  width: 9rem;
  height: 2rem;
  text-align: center;
  background-image: ${WMKGradient.grad()}
  color: ${colors.hex("white")};
  transition: right 0.5s ease-in-out;
  ${mq.max(
    "sm",
    `top: 36vw;
    width: 10rem;`
  )}
  ${mq.range(
    "sm",
    "lg",
    `top: 19rem;
    width: 7rem;`
  )}
  ${mq.range("sm", "loopMd", `top: 22rem;`)}
`;
