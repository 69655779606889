import { PageFields } from "../fragments/nodePageFields";
import { RichText } from "wmk-rich-text";

export class Page {
  title: string;
  content?: RichText;
  meta?: string;
  slug: string;
  titleBar?: string;
  constructor(node: PageFields) {
    const _node = { ...node };
    this.title = _node.title;
    this.content = new RichText(_node.content);
    this.meta = _node.meta?.description;
    this.slug = _node.slug;
    this.titleBar = _node.titleBar;
  }
}
