import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Fade } from "react-awesome-reveal";
import { Controller, Scene } from "react-scrollmagic";
import { colors } from "../../../../vars/palette";
import { WmkLink } from "wmk-link";
import Theme from "../../../../vars/ThemeOptions";
import { CaseStudy, CaseStudy3 } from "../../../../classes/CaseStudy";
import { WmkImage } from "wmk-image";

const SwipeWrap = styled.div`
  position: relative;
  height: 100vh;
  margin-left: -15px;
  margin-right: -15px;
  background: ${colors.hex("black")};
  .gatsby-image-wrapper,
  .gatsby-image-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100% !important;
  }
  .pin-content {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
  }
  .wmk-link {
    position: absolute;
    top: 40%;
    left: 0;
    display: block;
    width: auto;
    color: ${Theme.hex("white")};
    text-transform: uppercase;
    font-size: 1rem;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
    text-decoration: none;
    h2 {
      ${Theme.gradient.wmk}
      margin: 0;
    }
    p {
      ${Theme.gradient.rust}
      margin-bottom: 0;
    }
    & > * {
      padding: 1vh 2vw 1vh 8vw;
      transition: all 0.3s ease;
    }
    &:hover {
      text-decoration: none;
      & > * {
        text-decoration: none;
        padding: 1vh 2vw 1vh 10vw;
        transition: all 0.3s ease;
      }
    }
  }
`;

const SwipeControl = (props: { latest: (CaseStudy | CaseStudy3)[] }) => {
  return (
    <Controller globalSceneOptions={{ triggerHook: "onLeave" }}>
      {props.latest.map((cStudy, i) => {
        return (
          <Scene key={"full-case-" + i} pin>
            <SwipeWrap>
              <WmkImage image={cStudy.heroImage} />
              <Fade direction="up">
                <div className="pin-content">
                  <Container fluid>
                    <Row className="flex-column pin-row">
                      <Col style={{ display: "flex", alignContent: "center" }}>
                        <WmkLink to={cStudy.to} className="wmk-link">
                          <h2>{cStudy.clientName}</h2>
                          <p>View Case Study</p>
                        </WmkLink>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Fade>
            </SwipeWrap>
          </Scene>
        );
      })}
    </Controller>
  );
};

export default SwipeControl;
