import * as React from "react";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { colors } from "../../../vars/palette";

const SocialLinkWrap = styled(WmkLink)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
    h4 {
      color: ${colors.hex("primary")};
    }
  }
  h4 {
    color: ${colors.hex("secondary")};
    text-transform: uppercase;
    font-size: 85%;
    font-weight: 900;
    margin-top: 0.5rem;
  }
`;

export const NameLink = ({
  websiteUrl,
  children,
}: {
  websiteUrl: string | null;
  children: React.ReactNode;
}) => {
  const linkUrl = websiteUrl ? websiteUrl : "/team"
  return (
    <SocialLinkWrap to={linkUrl} target="blank">
      <h4>{children}</h4>
    </SocialLinkWrap>
  );
};
