import * as React from "react";
import { useState, useEffect } from "react";
import { Filter, Tiles } from "..";
import { Container } from "react-bootstrap";
import Swipe from "../../BlockSwipe/BlockSwipe";
import {
  CaseStudies,
  CaseStudy,
  CaseStudy3,
} from "../../../../../classes/CaseStudy";

type galleryProps = {
  works: CaseStudies;
};

export const Gallery = ({ works }: galleryProps) => {
  const [filteredWorks, setFilteredWorks] = useState<
    (CaseStudy | CaseStudy3)[]
  >([]);

  const [currCat, setCurrCat] = useState("All");

  useEffect(() => {
    setFilteredWorks(works.list);
  }, []);

  const handleFilter = (cat: string) => {
    if (cat === "All") {
      setFilteredWorks(works.list);
    } else {
      const filt = works.list.reduce((filtered, work) => {
        const industry = work.industry;
        if (typeof industry === "string" && industry === cat) {
          filtered.push(work);
        } else if (Array.isArray(industry)) {
          industry.forEach((ind) => {
            if (ind === cat) {
              filtered.push(work);
            }
          });
        }
        return filtered;
      }, []);

      setFilteredWorks([...filt]);
    }
  };

  const updateCurrCat = (clickedCat: string) => {
    if (clickedCat !== currCat) {
      setCurrCat(() => {
        handleFilter(clickedCat);
        return clickedCat;
      });
    }
  };

  // create array of unique categories for the filter
  const cats = works.list.reduce(
    (categories: { [key: string]: (CaseStudy | CaseStudy3)[] }, work) => {
      const _industry =
        work.type !== "ContentfulCaseStudy" ? [work.industry] : undefined;
      const _industries =
        work.type === "ContentfulCaseStudy" && Array.isArray(work.industry)
          ? work.industry
          : undefined;
      const industries: string[] = _industry
        ? [..._industry]
        : _industries
        ? [..._industries]
        : [];
      industries.forEach((industry) => {
        if (industry) {
          if (!categories[industry]) {
            categories[industry] = [];
          }
          categories[industry].push(work);
        }
      });

      return categories;
    },
    {}
  );
  cats["All"] = works.list;

  return (
    <Container fluid>
      <Swipe />
      <Filter
        cats={Object.keys(cats).reverse()}
        currCat={currCat}
        updateCurrCat={updateCurrCat}
      />
      <Tiles works={filteredWorks} />
    </Container>
  );
};
