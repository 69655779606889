import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import TeamSingle from "./TeamSingle";
import PageTitleBar from "../../common/PageTitleBar";
import { mq } from "../../../vars/mediaQueries";
import { PeopleFields } from "../../../fragments/nodePeopleFields";

const Team = () => {
  const { block }: { block: { edges: { node: PeopleFields }[] } } =
    useStaticQuery(graphql`
      {
        block: allContentfulWmkPeople(
          sort: { order: ASC, fields: sortOrder }
          filter: { status: { eq: "Team Member" } }
        ) {
          edges {
            node {
              ...NodePeopleFields
            }
          }
        }
      }
    `);
  const teamMembers = block.edges.map((e) => e.node);

  return (
    <>
      <PageTitleBar title="TEAM" />
      <MainWrap>
        <Container fluid>
          <Row>
            {teamMembers &&
              teamMembers.length &&
              teamMembers.map((member) => {
                const { name, jobTitle } = member;
                return (
                  <Col sm={12} md={6} lg={4} key={name + jobTitle}>
                    <Fade>
                      <TeamSingle memberInfo={member} />
                    </Fade>
                  </Col>
                );
              })}
          </Row>
        </Container>
      </MainWrap>
    </>
  );
};

export default Team;

const MainWrap = styled.div`
  margin: 3rem 5vw 3rem 10vw;
  ${mq.max("sm", "margin: 3rem 10vw 3rem 10vw;")}
`;
